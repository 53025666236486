/* =Events Calendar
--------------------------------------------------*/

// Calendar Display
// Uses FullCalendar 2
// Pulls events from XML file
// after render, moves the events data from one nested table to another


$(document).ready(function() {

  // for calendar page, main and secondary (home page, sidebar calendar)
  var calendarPage = false;
  if ($('#events-calendar').length) {
    calendarPage = true;
  }

  var secondaryCalendar = false;
  if ($('.secondary-calendar').length) {
    secondaryCalendar = true;
  }

  // eventsURL must appear on the template
  // var eventsURL = 'events2.xml';
  var currentDateStorage = 'currentDateStorage';
  var menuDateFormat = 'YYYY-M';
  var calendarDateFormat = 'YYYY-MM-DD';
  var feedDateFormat = 'M/D/YYYY hh:mm:ss a';
  var displayDateFormat = 'MMMM D YYYY';
  var days = ['<span class="first-letter">S</span><span class="remaining-letters">unday</span>', '<span class="first-letter">M</span><span class="remaining-letters">onday</span>', '<span class="first-letter">T</span><span class="remaining-letters">uesday</span>', '<span class="first-letter">W</span><span class="remaining-letters">ednesday</span>', '<span class="first-letter">T</span><span class="remaining-letters">hursday</span>', '<span class="first-letter">F</span><span class="remaining-letters">riday</span>', '<span class="first-letter">S</span><span class="remaining-letters">aturday</span>'];


  // for listing pages, including category pages
  var listingPage = false;
  if ($('.events-listing').length > 0) {
    listingPage = true;
  }
  var eventsPerPage = 10; // usually 20

  var businessName = 'Fort St. John'; // client name for add to calendar buttons
  var productName = 'Events Calendar'; // product name for add to calendar buttons

  // for the full listing page, the only one that has the category filters
  var listingAllPage = false;
  if ($('#events-all').length > 0) {
    listingAllPage = true;
  }

  // events detail page, just need add to calendar buttons
  var detailPage = false;
  if ($('#events-detail').length > 0) {
    detailPage = true;
  }

  var singleCategoryStorage = 'singleCategoryStorage';

  // for calendar & full listing
  var excludedCategoriesStorage = 'excludedCategoriesStorage';

  // functions for calendar

  // the menu uses zero indexed months: 2018-0
  // the calendar uses one indexed months: 2018-01-01
  function dateFormatMenuToCalendar(menuDate) {
    var arr = menuDate.split('-');
    calendarDate = moment(arr[0] + '-' + (parseInt(arr[1]) + 1), menuDateFormat).format(calendarDateFormat);
    return calendarDate;
  }

  function dateFormatCalendarToMenu(calendarDate) {
    var arr = calendarDate.split('-');
    calendarDateMoment = moment(calendarDate, calendarDateFormat);
    menuDate = calendarDateMoment.format('YYYY') + '-' + (parseInt(calendarDateMoment.format('M')) - 1);
    return menuDate;
  }

  // need to check local storage for these
  function getCurrentDate() {
    // start with the default, which is the first of this month
    var date = moment().startOf('month').format(menuDateFormat);

    // if it's already set, don't change it
    if (currentDate !== undefined) {
      date = currentDate;
    }
    // check for local storage but not if it's a secondary calendar
    else if (sessionStorageAvailable && (!(secondaryCalendar)) ) {
      var storage = sessionStorage.getItem(currentDateStorage);
      if (storage) {
        date = JSON.parse(storage);
      }
    }
    return date;
  }

  function updateCurrentDate(date) {

    // update var
    currentDate = date;

    // update sessionStorage but not on secondary calendars
    if (!(secondaryCalendar)) {
      sessionStorage.setItem(currentDateStorage, JSON.stringify(date));
    }
  }

  function goToCalendarDate() {
    eventsCalendar.fullCalendar('gotoDate', currentDate);
  }

  function setUpDateNavigation() {
    // set to current date
    // console.log(currentDate);
    $('#events-months option[value="' + dateFormatCalendarToMenu(currentDate) + '"]').attr('selected', 'selected');

    // add event for menu selection
    $('#events-months').change(function() {
      var date = $('#events-months option:selected').val();
      updateCurrentDate(dateFormatMenuToCalendar(date));
      goToCalendarDate();
    });

    // add event to update menu / currentDate on nav button click
    // next
    $('#calendar .fc-right').html('<button id="month-next" class="month-next button"><span class="text">Next</span><span class="arrow"> ›</span></button>');
    $('#calendar button#month-next').click(function(e) {
        var nextDate = moment(currentDate, calendarDateFormat).add(1, 'months').format(calendarDateFormat);
        updateCurrentDate(nextDate);
        $('#events-months option').removeAttr('selected');
        $('#events-months option[value="' + dateFormatCalendarToMenu(currentDate) + '"]').attr('selected', 'selected');
        goToCalendarDate();
        e.preventDefault();
        e.stopPropagation();
    });

    // prev
    $('#calendar .fc-left').html('<button id="month-prev" class="month-prev button"><span class="arrow">‹ </span><span class="text">Previous</span></button>');
    $('#calendar button#month-prev').click(function(e) {
      var prevDate = moment(currentDate, calendarDateFormat).subtract(1, 'months').format(calendarDateFormat);
      updateCurrentDate(prevDate);
      $('#events-months option').removeAttr('selected');
      $('#events-months option[value="' + dateFormatCalendarToMenu(currentDate) + '"]').attr('selected', 'selected');
      goToCalendarDate();
      e.preventDefault();
      e.stopPropagation();
    });

  }

  // recheck this after styling, not sure what has to change
  function updateCalendarCategories() {
    // calendarCategories = getSetting('eventsCategories');
    // $('#calendar .calendar-cell-wrapper').remove();
    $('#calendar td.calendar-has-event').removeClass('calendar-has-event');
    for (var j = 0; j < allCategories.length; j++) {
      $('#calendar td').removeClass(allCategories[j]);
    }
    eventsCalendar.fullCalendar('refetchEvents');
    // console.log(currentDate);
    // updateDateOptions();
  }

  // functions for listing & detail

  function createAddEventLinks(eventElement, eventID, eventStart, eventEnd, eventTitle, eventBusiness, eventProduct) {

    // add links
    var googleURL = 'https://www.google.com/calendar/event?action=TEMPLATE&text='+encodeURIComponent(eventTitle)+'&dates='+encodeURIComponent(eventStart)+'/'+encodeURIComponent(eventEnd)+'&details=&location=';

    var addHTML = '<div class="event event-add"><ul class="event-add-container"><li class="event-add-item"><a href="'+googleURL+'" target="_blank">Add to Google Calendar</a></li><li  class="event-add-item"><a href="#0" id="event-add-'+eventID+'" title="Add to Outlook/Apple Calendar">Add to Outlook/Apple Calendar</a></li></ul></div>';

    if (!detailPage) {
      $(eventElement).append(addHTML);
    }
    else {
      $('.event-submit-link').before(addHTML);
    }

    var eventDatestamp = moment().utc().format('YYYYMMDDTHHmmss')+ 'Z';
    // ical click
    $("#event-add-"+eventID).click( function(e) {
      var text = "BEGIN:VCALENDAR\nMETHOD:PUBLISH\nVERSION:2.0\nPRODID:-//"+eventBusiness+"//"+eventProduct+"//EN\nBEGIN:VEVENT\nUID:"+eventID+"\nDTSTAMP:"+eventDatestamp+"\nDTSTART:"+eventStart+"\nDTEND:"+eventEnd+"\nSUMMARY:"+eventTitle+"\nEND:VEVENT\nEND:VCALENDAR";
      var filename = 'event'+eventID;
      var blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      saveAs(blob, filename+".ics");
      e.preventDefault();
      e.stopPropagation();
    });
  }

  function paginateEvents() {
    // add and remove container

    $('#events-pager').remove();
    $('.events-listing').append('<div id="events-pager" class="pager events-pager"></div>');

     $('.events-listing').paginate({
        itemContainer: '#events-container',
        itemElement: 'li',
        itemsPerPage: eventsPerPage,
        navContainer: '#events-pager',
        pagerLabel: 'Events List Pagination',
      });
  }


  // functions for full listing

  function updateListingCategories(allEvents) {
    // reset full list
    $('#events-container').html(allEvents);
    // $('li.events-item').addClass('hidden');
    $.each(excludedCategories, function(index, value) {
      $('li.events-item.category-' + value).remove();
    });
    // paginate
    paginateEvents();
  }

  // functions for calendar & full listing

  function compareArrays(array1, array2) {
    array1.sort();
    array2.sort();
    var arraysSame = (array1.length == array2.length) && array1.every(function(element, index) {
        return element === array2[index];
    });
    return arraysSame;
  }

  // check for local/session storage
  function storageAvailable(type) {
    try {
      var storage = window[type],
          x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  }


  // get these from the on-page html because they can't be changed
  function getAllCategories() {
    var categories = [];
    $("#categories-container input[type=checkbox]").each(function() {
      if ($(this).val() != 'all') {
        categories.push($(this).val());
      }
    });
    return categories;
  }

  // need to check local storage for these
  function getExcludedCategories() {
    // start with the default, none are excluded
    var categories = [];

    // if it's already set, don't change it, even if it's empty
    if (excludedCategories !== undefined) {
      categories = excludedCategories;
    }
    // check for local storage
    else if (sessionStorageAvailable) {
      var storage = sessionStorage.getItem(excludedCategoriesStorage);
      if (storage) {
        categories = JSON.parse(storage);
      }
    }

    return categories;
  }


  function updateExcludedCategories(action, category) {
    var categories = excludedCategories;

    // console.log('a');

    if (action == 'remove') {
      var index = categories.indexOf(category);
      if (index > -1) {
        categories.splice(index, 1);
      }
    }

    // means "show all categories"
    else if (action == 'all') {
     categories = [];
    }

    // add is the default
    // but check if it's already there
    else if ((category != 'all') && ($.inArray(category, excludedCategories) == -1)) {
        categories.push(category);
    }

    // update var
    excludedCategories = categories;

    // update sessionStorage
    sessionStorage.setItem(excludedCategoriesStorage, JSON.stringify(categories));

  }


  function setUpCategoryFiltering() {
    // add divs to split the list into columns

    var columns = 2;
    var categoryItem = 'label';
    var columnClass = 'column';

     $('#categories-container').each(function() {
      var items_per_col = new Array(),
      items = $(this).find(categoryItem),
      min_items_per_col = Math.floor(items.length / columns),
      difference = items.length - (min_items_per_col * columns);
      for (var i = 0; i < columns; i++) {
          if (i < difference) {
              items_per_col[i] = min_items_per_col + 1;
          } else {
              items_per_col[i] = min_items_per_col;
          }
      }
      for (var i = 0; i < columns; i++) {
          $(this).append($('<div ></div>').addClass(columnClass));
          for (var j = 0; j < items_per_col[i]; j++) {
              var pointer = 0;
              for (var k = 0; k < i; k++) {
                  pointer += items_per_col[k];
              }
              $(this).find('.' + columnClass).last().append(items[j + pointer]);
          }
      }
     });

    // check on categories & set up click filtering
    $('#categories-container input[type=checkbox]').each(function() {
      var category =  $(this).val();

      if (category != 'all') {

        if ($.inArray(category, excludedCategories) == -1) {
          $(this).prop('checked', true);
        }
        // if none are excluded, check all as well
        if (excludedCategories.length === 0) {
          $('#categories-container input#all-categories').prop('checked', true);
        }
        $(this).change(function(e) {
          // console.log( $(this).prop('checked'));
          var change = $(this).prop('checked');
          if (change === true) {
            updateExcludedCategories('remove', category);
          }
          else {
            updateExcludedCategories('add', category);
          }
          // check if All needs to be checked on
          if (excludedCategories.length === 0) {
            $('#categories-container input#all-categories').prop('checked', true);
          }
          else {
            $('#categories-container input#all-categories').prop('checked', false);
          }

          if (calendarPage) {
            // calendar update
            updateCalendarCategories();
          }

          if (listingAllPage) {
            updateListingCategories(allEvents);
            // paginate
          }

          e.preventDefault();
          e.stopPropagation();
        });
      }
    });

    // all

    $('#categories-container input#all-categories').change(function(e) {
      // console.log( $(this).prop('checked'));
      var change = $(this).prop('checked');
      if (change === true) {
        // check all
        $('#categories-container input[type=checkbox]').prop('checked', true);
        // update included categories
        updateExcludedCategories('all', 'all');
        // update calendar
        // updateCalendarCategories();
      }
      // if it's unchecked, it doesn't matter at all

      if (calendarPage) {
        // calendar update
        updateCalendarCategories();
      }

      if (listingAllPage) {
        updateListingCategories(allEvents);
        // paginate
      }

      e.preventDefault();
      e.stopPropagation();
    });

  }

  var sessionStorageAvailable = false;
  if (storageAvailable('sessionStorage')) {
    sessionStorageAvailable = true;
    $('.calendar').addClass('calendar-storage_available');
  }

  // main calendar page and list all events page are the only ones with the category filter
  if (listingAllPage || (calendarPage && !(secondaryCalendar)) ) {


    var allCategories = getAllCategories();
    var excludedCategories = getExcludedCategories();

    // if we're coming from a detail page, we need to check for the single category to filter on
    // why that detail link can't just go to the category page, we will never know...

    if (listingAllPage) {

      if (sessionStorageAvailable) {

        // got to check for the single category first; that gets sent from the detail page

        // if it's there, grab the value

        var singleCategory = sessionStorage.getItem(singleCategoryStorage);
        // console.log(singleCategory);
        if (singleCategory) {

          // set excluded to all, then rm the single one

          excludedCategories = getAllCategories();

          updateExcludedCategories('remove', singleCategory);

          // then remove the single category from session storage
          sessionStorage.removeItem(singleCategoryStorage)
        }

      }
    }

    setUpCategoryFiltering();
  }

  if (secondaryCalendar) {
    // always show all events even if they've filtered them on the main calendar page
    // but don't update session storage
    var excludedCategories = '';
  }

  if (calendarPage) {
    var currentDate = getCurrentDate();
    // we still have to use CSS to remove the .fc-content-skeleton divs
    // just an easier way of dealing with it
    var eventsCalendar = $('#calendar').fullCalendar({
      header: {
        left: '',
        center: 'title',
        right: ''
      },
      navLinks: false,
      handleWindowResize: false,
      height: 'auto',
      // dayNamesShort: days,
      columnHeaderHtml: function(mom) {
        for (i=0; i<=6; i++) {
          if (mom.day() === i) {
            return days[i];
          }
        }
      },
      defaultDate:  moment(currentDate, menuDateFormat).format(calendarDateFormat),
      // defaultDate: moment('20180701', 'YYYYMMDD').format('YYYY-MM-DD'),
      displayEventTime: false,
      events: function(start, end, timezone, callback) {
        $.ajax({
          url: eventsURL,
          dataType: 'xml',
          cache: false,
          success: function(doc) {
            var events = [];
            $(doc).find('event').each(function() {
              var days = $(this).attr('days');
              // console.log($.inArray($(this).attr('cat'), excludedCategories));
              if ($.inArray($(this).attr('cat'), excludedCategories) == -1) {
                for(var j = 0; j < parseInt(days); j++) {
                  events.push({
                    title: $(this).attr('name'),
                    start: moment($(this).attr('startDate'), feedDateFormat).add(j, 'days'),
                    url: $(this).attr('url'),
                    category: $(this).attr('cat')
                  });
                }
              }
            });
            callback(events);
          },
          error: function() {
            // console.log('sorry');
          }
        });
      },
      eventRender: function(event, element) {
        var day = event.start.format(calendarDateFormat);

        element.addClass('category-' + event.category + ' calendar-event');
        var theEvent = element;
        $('td[data-date="'+day+'"]').addClass(event.category).addClass('calendar-has-event').append(element);
      },
      eventAfterAllRender: function() {
        $('.fc-bg td.fc-day .calendar-cell-wrapper').remove();

        $('.fc-content-skeleton thead td').each(function() {
          var date = $(this).data('date');
          var day = $(this).html();

          $('.fc-bg').find('td[data-date='+ date +']').prepend(day);
          // wrap events in UL, add number of events per day
          $('.fc-bg td.calendar-has-event').children('a.fc-event').wrap('<li class="calendar-event-item">');
        });

        $('.fc-bg td.calendar-has-event').each(function() {

          var eventDayMoment = moment($(this).data('date'), calendarDateFormat);

          var eventDay = eventDayMoment.format(displayDateFormat);

          $(this).children('li').wrapAll('<ul class="calendar-event-container">');
          $(this).children('ul.calendar-event-container').wrap('<span class="calendar-event-wrapper">').before('<h3>' + eventDay + '</h3>');
          if (($(this).children('.total').length) < 1) {
            var total = $(this).find('li').length;
            $(this).append('<div class="total">' + total + ' Event' + ((total > 1) ? 's' : '') + '</div><div class="calendar-categories" />');
          }
          if (!(secondaryCalendar)) {
            for (var j = 0; j < allCategories.length; j++) {
              if ($(this).hasClass(allCategories[j])) {
                $(this).find('.calendar-categories').append('<span class="'+allCategories[j]+'"></span>');
              }
            }
          }

        });

        $('.fc-bg td.fc-day').wrapInner('<div class="calendar-cell-wrapper"></div>');
      }
    });
    // set up month menu
    setUpDateNavigation();
  }

  if (listingPage) {
    $('.events-item').each(function(){
      var metaElement = $(this).find('.events-meta');
      createAddEventLinks(metaElement, $(this).data('uid'), $(this).data('start'), $(this).data('end'), $(this).data('title'), businessName, productName);
    });

    if (!(listingAllPage)) {
      paginateEvents();
    }
  }

  if (listingAllPage) {

    // hide/show the categories
    var allEvents = $('#events-container').html();
    updateListingCategories(allEvents);
  }
  if (detailPage) {
    // add to calendar links, view category link


    var eventMeta = $('.events-detail');

    createAddEventLinks(eventMeta, eventMeta.data('uid'), eventMeta.data('start'), eventMeta.data('end'), eventMeta.data('title'), businessName, productName);

    if (sessionStorageAvailable) {

      $('.events-category-link a').click(function(e) {
          var eventCategory = $('.events-detail').data('category');
          var destination = $(this).attr('href');
          sessionStorage.setItem(singleCategoryStorage, eventCategory);

          window.location.href = destination;

          e.preventDefault();
          e.stopPropagation();
      });
    }
  }



});
